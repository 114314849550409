<template>
  <div class="home-m">
    <header-m></header-m>
    <div class="swiper">
      <el-carousel arrow="always" :height="bannerHeight + 'px'">
        <el-carousel-item v-for="(item, index) in swiperlist" :key="index">
          <video
            v-if="item.targetType == 'video'"
            id="vod0"
            :src="item.bannerUrl"
            loop
            autoplay
            preload
            muted
            :style="{ width: bannerWidth + 'px', height: bannerHeight + 'px' }"
          ></video>
          <img v-else class="bannerImg" :src="item.bannerUrl" ref="image" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="intro">
      <p>
        苏州雄霸网络有限公司，致力于手机游戏开发制作
        苏州雄霸网络有限公司（以下简称“雄霸网络”）成立于2016年8月，是国内优秀的独立游戏开发商。
        成立至今，雄霸网络专注于手机游戏的开发，倾力打造有深度的精品手游。
      </p>
      <p>
        目前公司游戏处于开发期间，公司前期主要以独立游戏为主，后续将紧跟移动互联网的时代浪潮，把重心转移至手机网络游戏开发。
        以高端玩家自主研发技术为支撑，以玩家需求为核心且不拘泥于俗套的创新意识为主导，以精致的游戏画面、炫酷的游戏特效。
        充满代入感的背景音乐为保障，力求为玩家呈现完美的饕餮盛宴。
      </p>
    </div>
    <div class="text-content">
      <div class="menu-index">
        <div class="des">
          <router-link to="/homem">首页</router-link>
          <router-link to="/aboutm">关于我们</router-link>
          <router-link to="/gamem">产品介绍</router-link>
          <router-link to="/joinm">加入我们</router-link>
          <router-link to="/contactm">联系我们</router-link>
        </div>
        <div class="qr_code">
          <div class="qr_item">
            <img src="../assets/img/home_weixin.png" />
            <p>关注雄霸网络官方网站</p>
          </div>
        </div>
      </div>
    </div>
    <footer-content></footer-content>
  </div>
</template>
<script>
// import '@/assets/js/flexible'
import HeaderM from "../components/HeaderM.vue";
import FooterContent from "@/components/FooterContent.vue";
import { isMobile } from '@/utils/util'
export default {
  name: "HomeM",
  components: {
    HeaderM,
    FooterContent
  },
  data() {
    return {
      bannerWidth: 400,
      bannerHeight: 200,
      screenWidth: 400,
      screenHeight: 200,
      swiperlist: [
        // {
        //   bannerTargetUrl: "",
        //   targetType: "video",
        //   bannerUrl: require("../assets/img/video1.mp4"),
        // },
        // {
        //   bannerTargetUrl: "",
        //   targetType: "video",
        //   bannerUrl: require("../assets/img/video2.mp4"),
        // },
        {
          bannerTargetUrl: "",
          targetType: "img",
          bannerUrl: require("../assets/img/home_banner01.jpg")
        },
        {
          bannerTargetUrl: "",
          targetType: "img",
          bannerUrl: require("../assets/img/game_list02.jpg")
        }
      ]
    };
  },
  mounted() {
    if (isMobile()) {
      // alert("手机端");
      // this.$router.replace("/homem");
    } else {
      // alert("pc端");
      this.$router.replace("/");
    }
  },
  methods: {
    // isMobile() {
    //   let flag = navigator.userAgent.match(
    //     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    //   );
    //   return flag;
    // }
  }
};
</script>
<style lang="scss" scoped>
.home-m {
  .intro {
    margin-top: 30px;
    p {
      font-size: 16px;
      width: 100%;
      padding: 5px 30px;
      margin: 0;
      color: #888;
      text-align: justify;
      box-sizing: border-box;
    }
  }
}
.swiper {
  margin-top: 60px;
}
.el-carousel__item {
  h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  a {
    display: block;
  }
  video {
    display: block;
  }
}

.el-carousel__item:nth-child(2n) {
  background-color: #fff;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #fff;
}
.bannerImg {
  display: block;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.text-content {
  background-color: #fff;
  .menu-index {
    width: 100%;
    font-size: 16px;
    .qr_code {
      box-sizing: border-box;
      width: 100%;
      margin: 50px 0;
      .qr_item {
        display: block;
        text-align: center;
        width: 100%;
        img {
          width: 100px;
          vertical-align: middle;
        }

        p {
          font-size: 12px;
          color: #494949;
          margin-top: 10px;
        }
      }
    }
    .des {
      padding: 0 50px;
      a {
        line-height: 80px;
        display: block;
        color: #555;
        text-decoration: none;
        border-bottom: 1px solid #f5f5f5;
      }
    }
  }
}
</style>
